import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import './profile.css';

const Return = () => {
  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');

  const handleSubmit = async () => {
    try {
      const Token = localStorage.getItem("token");
      const response = await axios.post('https://eccomerce-av7e.onrender.com/api/v1/update-order', {
        orderId: orderId,
        status: reason
      }, {
        headers: {
          Authorization: `Bearer ${Token}`
        }
      });
      
      toast.success(response.data.msg);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className='return-section'>
      <div className='container'>
        <h2>Return</h2>
        <div className='return-box'>
          <div className='transition-input'>
            <label htmlFor='orderId'>Order ID:</label>
            <input 
              type='text' 
              name='orderId' 
              id='orderId' 
              value={orderId} 
              onChange={(e) => setOrderId(e.target.value)} 
            />
          </div>
          <div className='selection-option'>
            <select 
              name='reason' 
              id='reason' 
              value={reason} 
              onChange={(e) => setReason(e.target.value)}
            >
              <option value='select'>--Select--</option>
              <option value='Return'>Return</option>
              <option value='Cancel'>Cancel</option>
            </select>
          </div>
          <button className='buttons' onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Return;
