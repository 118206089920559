import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
    
  useEffect(() => {
    // Retrieve authentication status from localStorage
    const authStatus = localStorage.getItem('auth');
    setIsAuth(authStatus === 'true');
  }, []); // Run this effect only once on component mount

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
