import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Set axios defaults
axios.defaults.withCredentials = true;

const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://eccomerce-av7e.onrender.com/api/v1/my-order');
        setOrders(response.data);
        setLoading(false);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div>
      <h1>My Orders</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        // <ul>
        //   {orders.map(order => (
        //     <li key={order.id}>
        //       Order ID: {order.id}, Amount: {order.amount}
        //     </li>
        //   ))}
        // </ul>
        <h2>I am Goog</h2>
      )}
    </div>
  );
};

export default MyOrder;
