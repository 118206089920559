import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import { Toaster } from 'react-hot-toast';
import { lazy, Suspense, useState } from 'react';
import Metadata from './component/Meta/Metadata';
import Profile from './component/Profile/Profile';
import Order from './component/Profile/Order';


const Home = lazy(() => import('./component/home/Home'));
const Login = lazy(() => import('./component/auth/Login'));
const Register = lazy(() => import('./component/auth/Register'));
const SingleProduct = lazy(() => import('./component/product/SingleProduct'));
const Cart = lazy(() => import('./component/cart/Cart'));
const Aboutus = lazy(() => import('./component/About/Aboutus'));
const Contact = lazy(() => import('./component/contact/Contact'));
const Product = lazy(() => import('./component/product/Product'));
const PrivacyPolicy = lazy(() => import('./component/Privacy-Policy/PrivacyPolicy'));
const Termcondition = lazy(() => import('./component/Termcondition/Termcondition'));
const Shipping = lazy(() => import('./component/shipping/Shipping'));
const Refund = lazy(() => import('./component/refund/Refund'));
const PaymentSuccess = lazy(() => import('./component/paymentSuccess/PaymentSuccess'));
const OrderForm = lazy(() => import('./component/cart/OrderForm'));

function App() {
  const [cart, setCart] = useState([]);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Suspense fallback={<div className='div-loader'>
          <p className='laoding'>Loading...</p>
        </div>}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Metadata title="Home" />
                  <Home />
                </>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <Metadata title="Login" />
                  <Login />
                </>
              }
            />
            <Route
              path="/register"
              element={
                <>
                  <Metadata title="Register" />
                  <Register />
                </>
              }
            />
            <Route
              path="/product-info/:id"
              element={
                <>
                  <Metadata title="Product Info" />
                  <SingleProduct addToCart={setCart} cart={cart} />
                </>
              }
            />
            <Route
              path="/cart"
              element={
                <>
                  <Metadata title="Cart" />
                  <Cart cartItems={cart} setCart={setCart} />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Metadata title="About Us" />
                  <Aboutus />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Metadata title="Contact Us" />
                  <Contact />
                </>
              }
            />
            <Route
              path="/shop"
              element={
                <>
                  <Metadata title="Shop" />
                  <Product />
                </>
              }
            />
            <Route
              path="/term"
              element={
                <>
                  <Metadata title="Terms and Conditions" />
                  <Termcondition />
                </>
              }
            />
            <Route
              path="/policy"
              element={
                <>
                  <Metadata title="Privacy Policy" />
                  <PrivacyPolicy />
                </>
              }
            />
            <Route
              path="/shipping-policy"
              element={
                <>
                  <Metadata title="Shipping Policy" />
                  <Shipping />
                </>
              }
            />
            <Route
              path="/refund"
              element={
                <>
                  <Metadata title="Refund Policy" />
                  <Refund />
                </>
              }
            />
            <Route
              path="/payform"
              element={
                <>
                  <Metadata title="Order Form" />
                  <OrderForm />
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <Metadata title="Profile" />
                  <Profile />
                </>
              }
            />
            <Route
              path="/paymentsuccess/:transication/:value"
              element={
                <>
                  <Metadata title="Payment Success" />
                  <PaymentSuccess />
                </>
              }
            />
          </Routes>
        </Suspense>
        <Footer />
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;
