// Metadata.jsx
import React, { useEffect } from 'react';

const Metadata = ({ title }) => {
  useEffect(() => {
    document.title = `${title} - seoneg7g`;
  }, [title]);

  return null; // Metadata component doesn't render anything directly
};

export default Metadata;
